import api from "!../../../../../../../tmp/drone/cache/361e892f085ddff298fe0311d71822ac3b91798198449f3df0dfa7708a34556f/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../../../../../tmp/drone/cache/361e892f085ddff298fe0311d71822ac3b91798198449f3df0dfa7708a34556f/node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!./flag-icons.css";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};